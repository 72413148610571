import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";

import moment from 'moment';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Navigation from '../Navigation';

import Queues from './Queues';
import Users from './Users';
import ActiveQueueEntries from './ActiveQueueEntries';

import DatePicker from 'react-datepicker'

const CommunicationLive = ({selected_division}) => {

    const [day, setDay] = useState(new Date())
    const [end, setEnd] = useState(new Date())

    const startTime = parseInt(moment(day).startOf('day').format('X'))
    const endTime = parseInt(moment(end).endOf('day').format('X'))

    return (

        <>

        <Helmet>
            <title>Communication</title>
            <meta name="description" content="Communication" />
        </Helmet>

        <HeaderNavigation 
            title="Communication"
            description={`${selected_division.name ? selected_division.name : 'All'} Phone Calls`}
            leftColSize={4}
            actionComponent={(
                <Row>
                    <Col md={6}></Col>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Select Start</p>
                        <DatePicker 
                            selected={day} 
                            onChange={(date) => setDay(date)} 
                        />
                    </Col>
                    <Col md={3}>
                        <p className="text-sm mb-0">Select End</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => setEnd(date)} 
                        />
                    </Col>
                
                </Row>
            )}
            
        />

        <Container fluid>

            <Navigation />

            {!selected_division._id ? (
                <div className="alert alert-info z-depth-1"><i className="fas fa-info-circle mr-2 " /> When viewing all divisions this page may not refresh in live time.</div>
            ) : ''}

            <Row>
                
                <Col md={6}>
                    <Queues start={startTime} end={endTime} />
                </Col>

                <Col md={6}>
                    <ActiveQueueEntries/>
                    <Users  />
                </Col>

            </Row>
    
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CommunicationLive);
