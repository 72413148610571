import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';


import api from 'api';

// import hasPrivilege from 'utils/hasPrivilege'

const Sidebar = ({PLATFORM, toggleSidenav, sidenavOpen, socket, viewing_user, selected_division}) => {

    const location = useLocation(useLocation)

    const items = [
      
     
        // ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY') ? [ {
        //     active: () => location.pathname === '/',
        //     name: `Today's Activity`,
        //     link: '/'
        // }] : []),

        {
            active: () => location.pathname === '/module/bk',
            name: `Daily Reports`,
            link: '/module/bk'
        },
        // {
        //     active: () => location.pathname === '/module/bk/this_week',
        //     name: `This Week`,
        //     link: '/module/bk/this_week'
        // },
        // {
        //     active: () => location.pathname === '/module/bk/next_week',
        //     name: `Next Week`,
        //     link: '/module/bk/next_week'
        // },
        {
            active: () => location.pathname === '/module/bk/outstanding_items',
            name: `Tasks / Items`,
            link: '/module/bk/outstanding_items'
        },
        {
            active: () => location.pathname === '/module/bk/docket_search',
            name: `Docket Search`,
            link: '/module/bk/docket_search'
        },
        {
            active: () => location.pathname === '/module/bk/cases_filed',
            name: `Cases Filed`,
            link: '/module/bk/cases_filed'
        },
        {
            active: () => location.pathname === '/module/bk/hearings',
            name: `Hearings`,
            link: '/module/bk/hearings'
        },

        {
            active: () => location.pathname === '/module/bk/filing_fee_deadlines',
            name: `Filing Fee Deadlines`,
            link: '/module/bk/filing_fee_deadlines'
        },
       
        // {
        //     active: () => location.pathname === '/',
        //     name: `Objections`,
        //     link: '/'
        // },
        // {
        //     active: () => location.pathname === '/',
        //     name: `Garnishments`,
        //     link: '/'
        // },
        // {
        //     active: () => location.pathname === '/',
        //     name: `Cases By Stage`,
        //     link: '/'
        // },

       
    ]
    
    const [counts, setCounts] = useState({
        tasks: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        events: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        documents: {
            pending: 0,
            expired: 0,
        },
        texts: 0,
        emails: 0,
        support_tickets: 0,
        voicemails: 0,
    })
   
    const [sidebar, setSidebar] = useState({
        matters: 'closed',
        contacts: 'closed',
        calls: 'closed',
        pipeline: 'closed',
        tasks: 'closed',
        events: 'closed',
        documents: 'closed',
        texts: 'closed',
        emails: 'closed',
    })

    const onSetSidebar = useCallback((field) => {
        const _sidebar = JSON.parse(JSON.stringify(sidebar));
        _sidebar[field] = _sidebar[field] === 'open' ? 'closed' : 'open'
        setSidebar(_sidebar)
    }, [sidebar])

    const fetchSidebarData = useCallback(async () => {
        const data = await api.dashboard.sidebar(selected_division._id ? selected_division._id : 'all');
        if(data.data) setCounts(data.data)
    }, [selected_division._id])

   
    useEffect(() => {
        fetchSidebarData();
    }, [fetchSidebarData])

    return (
        <>

            {sidenavOpen ? <div onClick={toggleSidenav} className='background-blackout' /> : ''}

            <div className={`archk-sidebar z-depth-1 ${sidenavOpen ? 'open' : ''}`}>


                <div className="py-3 px-4 border-botto text-center">
                    <h2 className='pt-1 text-uppercase'>
                        <div className='text-white' style={{paddingTop: 3}}><img src={PLATFORM.FAVICON} style={{width: 20, position: 'relative', top: -2}} alt="Intuidesk" className="mr-3"/>
                        IntuiDesk </div>
                    </h2>
                </div>

                <div className=' bg-dark border-top  px-4 border-bottom'>
                    <h4 className='text-white py-3 mb-0 text-uppercase'>Actionable Insights</h4>
                </div>

                <div className='border-top'>
                    {items.map((item, i) => {

                        const active = item.active ? item.active(location) ? 'active' : '' : '';
                        const count1 = counts[item.itemField]
                        const textColor = 
                            count1 < 10 ? 'text-info' :
                            count1 <= 49 ? 'text-warning' :
                            'text-danger';

                        const main = (
                            <div 
                                key={i} 
                                className={`py-3 px-4 border-bottom cursor-pointer ${active ? 'active' : ''} `}
                                onClick={() => onSetSidebar(item.field)} 
                            >
                                {/* {console.log(item)} */}
                                <h4 className='mb-0'>
                                    {item.icon && <i style={{display: 'inline-block', width: 20,}} className={item.icon} />}
                                    {item.name} 
                                    {item.itemField && count1 ? <span style={{textShadow: '1px 1px 1px #333', fontSize: 16, marginTop: -2}} className={`float-right font-weight-bold ${textColor}`}>({counts[item.itemField]}) <i className="fas fa-caret-down ml-2 " /></span> : ''}
                                </h4>
                            </div>
                        )

                        return (
                            <div 
                                onClick={() => {
                                    if(sidenavOpen) toggleSidenav();
                                }} 
                                key={i} 
                                className={active ? 'active' : ''}
                            >
                                {item.link ? <Link to={item.link} >{main}</Link> : main }
                                    
                                {item.dropdown && sidebar[item.field] === 'open' && (
                                    <div className="py-3 pr-4 pl-5 border-bottom bg-secondary">
                                        {item.dropdown && item.dropdown.map((d, ii) => {

                                            const count2 = counts[d.itemField]
                                            const color = count2 < 10 ? 'text-info' :
                                            count2 <= 49 ? 'text-warning' :
                                            'text-danger';
                                            return (
                                                <Link to={d.link}>
                                                    <h4 
                                                        className={`py-1 ${d.active ? d.active(location) ? 'active-sub-item' : '' : ''}`} 
                                                        key={ii}
                                                    >
                                                        {d.name} 
                                                        {d.itemField !== undefined && count2 ? (
                                                            <span color={color} style={{minWidth: 40, fontSize: 14}} className={`float-right text-right t `}>
                                                                {counts[d.itemField] > 99 ? '99+' : counts[d.itemField]}
                                                            </span>
                                                        ) : ''}
                                                    </h4>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            
            </div>
        </>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
	    PLATFORM: state.load.PLATFORM,
        matter: state.matters.selected_matter,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
