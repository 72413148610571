import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, Progress, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

import formatText from 'utils/formatText';

import api from 'api';
import moment from 'moment';


import DatePicker from 'react-datepicker';

const SupervisorTodaysActivity = ({selected_division, DOMAINS}) => {

    const [data, setData] = useState(null)
    const [day, setDay] = useState(moment().startOf('day').toDate())
    const [user, setUser] = useState('')

    const all = user && data ? data.filter(d => d.assigned_to && d.assigned_to.includes(user)) : data;

    const finished = all ? all.filter (d => d.outcome) : []
    const unfinished = all ? all.filter (d => !d.outcome) : []

    let percent = all && finished.length && unfinished.length ? parseFloat(((finished.length / all.length) * 100).toFixed(2)) : !unfinished.length ? 100 : 0

    const fetchData = useCallback(async () => {

        const startTime = parseInt(moment(day).startOf('day').format('X'))
        const endTime = parseInt(moment(day).endOf('day').format('X'))

        const filter = { 
            start : startTime,
            end   : endTime
        }

        setData(null);
        const result = await api.modules.bk.supervisor.daily(selected_division._id, filter )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        setData(result.data)
    }, [day, selected_division._id])

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

    return (

        <>

            <Helmet>
                <title>{`Daily Reports`}</title>
                <meta name="description" content="Daily Reports" />
            </Helmet>

            <HeaderNavigation 
                title="Daily Activity"
                description="Today At A glance"
                leftColSize={4}
                actionComponent={(
                    <Row>
                        <Col md={4}></Col>

                        <Col md={4}>
                            <p className="text-sm mb-0">Search By User</p>
                            <SearchCollections
                                collection={'users'}
                                hideTitle={true}
                                placeholder="Search..."
                                value={user}
                                onChange={(obj) => setUser(obj.value)}
                                filter={{
                                    division: selected_division._id,
                                }}
                            /> 
                        </Col>

                        <Col md={4}>
                            <p className="text-sm mb-0">Select Day</p>
                            <DatePicker 
                                selected={day} 
                                onChange={(date) => setDay(date)} 
                            />
                        </Col>
                    
                    </Row>
                )}
            />

            {!data ? <Circle /> : (
                <Container className="ful mt--4 pt-4" fluid>

                    <Card className='my-3'>
                        <CardBody>
                            <div className="progress-wrapper pt-3">
                                <div className={`progress-info mb-0 mt--2`}>
                                    <div className="progress-label">
                                        <span className={`bg-white font-weight-bold text-sm ml--4 text-succes`}>
                                            Task Completion
                                        </span>
                                    </div>
                                    <div className="progress-percentage">
                                        <span>{percent}%</span>
                                    </div>
                                </div>
                                <Progress max="100" value={percent} color={"success"} />
                                <div className="mt--2">{finished.length}/{data.length}</div>
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Uncompleted Tasks</CardTitle>
                        </CardHeader>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 80}}>Due</th>
                                        <th>Item</th>
                                        <th>Last Comment</th>
                                        <th>Matter</th>
                                        <th className="text-center">Priority</th>
                                        <th>Assigned To</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {unfinished.length ? unfinished.map(d => (
                                        <tr key={d._id}>
                                            <td>{moment.unix(d.due_date).format('h:mm A')}</td>
                                            <td>
                                                <A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Items&selectedItem=${d._id}`}>
                                                    {d.name}
                                                </A>
                                            </td>
                                            <td>
                                                {d.last_comment ? (
                                                    <span>
                                                        {formatText(d.last_comment).stripHTML(100)}
                                                        {' - '}
                                                        <b className="text-info">{d.last_comment_by ? <ObjectFinder collection="users" _id={d.last_comment_by}/> : 'System'}</b>
                                                    </span>
                                                ) : ''}
                                               
                                            </td>
                                            <td>
                                                <A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Items&selectedItem=${d._id}`}>
                                                    <ObjectFinder collection="matters" _id={d.matter} />
                                                </A>
                                            </td>
                                            <td className="text-center">
                                                {
                                                    d.priority === 1 ? <Badge color="danger">Highest</Badge> :
                                                    d.priority === 2 ? <Badge color="warning">High</Badge> :
                                                    d.priority === 3 ? <Badge color="info">Default</Badge> :
                                                    d.priority === 4 ? <Badge color="purple">Low</Badge> :
                                                    d.priority === 5 ? <Badge color="success">Lowest</Badge> :
                                                    ''
                                                }
                                            </td>
                                            <td>
                                                {d.assigned_to && d.assigned_to.length ? d.assigned_to.map((a, i) => (
                                                    <span key={i}>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={a} /></span>
                                                )) : '-'}
                                            </td>
                                           
                                            <td className="text-right">
                                                <A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Items&selectedItem=${d._id}`} className="btn btn-info btn-sm">
                                                    <i className="fas fa-link mr-2" /> Open
                                                </A>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td style={{width: 200}}>No Items Found For This Day</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Finished Tasks</CardTitle>
                        </CardHeader>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 80}}>Due</th>
                                        <th>Item</th>
                                        <th>Matter</th>
                                        <th>Outcome</th>
                                        <th>Finished By</th>
                                        <th className="text-right">Finished At</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {finished.length ? finished.map(d => (
                                        <tr key={d._id}>
                                            <td>{moment.unix(d.due_date).format('h:mm A')}</td>
                                            <td>
                                                <A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Items`}>
                                                    {d.name}
                                                </A>
                                            </td>
                                            <td>
                                                <A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Items`}>
                                                    <ObjectFinder collection="matters" _id={d.matter} />
                                                </A>
                                            </td>
                                            <td>
                                                {d.outcome}
                                            </td>
                                            <td>
                                                <ObjectFinder collection="users" _id={d.finished_by} />
                                            </td>
                                            <td className="text-right">
                                                {moment.unix(d.finished_at).format('MM/DD/YYYY h:mm A')}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td style={{width: 200}}>No Items Found For This Day</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                
                   
                
                </Container>
            )}

        </>
    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SupervisorTodaysActivity);
