import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import store from 'store';

import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

import api from 'api';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import formatText from 'utils/formatText';

import { CSVLink } from "react-csv";


const SupervisorTodaysActivity = ({selected_division, COLORS, DOMAINS}) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [day, setDay] = useState(new Date())
    const [user, setUser] = useState(window.location.href.includes('localhost') ? '631e5c687418496a7ab0cb37' : '')
    const [csvData, setCSVData] = useState(null)

    // const [user, setUser] = useState('')

    const fetchData = useCallback(async () => {
        if(!user) return;
        setLoading(true);

        const filter = { 
            start : parseInt(moment(day).startOf('day').format('X')), 
            end   : parseInt(moment(day).endOf('day').format('X')),
            user
        }

        const result = await api._supervisor.userActivity(selected_division._id, filter )
        setLoading(false);

        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)
        const timeline = result.data.timeline ? result.data.timeline : []
        // timeline.unshift({ timeline_type: 'start', timeline_timestamp: parseInt(moment().startOf('day').add(7, 'hours').format('X')) })
        // timeline.push({ timeline_type: 'end', timeline_timestamp: parseInt(moment().endOf('day').format('X')) })
        setData(timeline)

    }, [selected_division._id, day, user])

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    return (

        <>

            <Helmet>
                <title>{`Todays Activity`}</title>
                <meta name="description" content="Todays Activity" />
            </Helmet>

            <HeaderNavigation 
                title="User Activity"
                description={data && data.length ? (
                    <CSVLink
                        data={csvData ? csvData.data : []} 
                        // headers={[]} 
                        filename={csvData && csvData.filename ? csvData.filename + ' - User Activity.csv' : "user-activity.csv"}
                        asyncOnClick={true} 
                        onClick={(event, done) => {
                            if(csvData) return true;
                            event.preventDefault()

                            const formatted = []
                            const state = store.getState();
                            const matters = state.objects.matters
                            const users = state.objects.users
                            const contacts = state.objects.contacts

                            let filename;

                            data.forEach(d => {
                                const matter = matters[d.matter];
                                const user = users[d.user];
                                const contact = contacts[d.contact];

                                if(user) filename = user.display_name

                                formatted.push({
                                    matter: matter ? matter.name : contact ? contact.display_name ? contact.display_name : contact.phone : '',
                                    type: d.timeline_type ? d.timeline_type.replace(/_/g, ' ').toUpperCase() : '-',
                                    status: d.status ? d.status : '-',
                                    timestamp: moment.unix(d.timeline_timestamp).format('MM/DD/YYYY h:mm A')
                                })
                            })

                            setCSVData({
                                data: formatted,
                                filename
                            })

                            setTimeout(() => {
                                event.target.click()

                                setTimeout(() => {
                                    setCSVData(null)
                                }, 500)
                            }, 250)

                            return false;

                        }}
                    >
                        <button className="btn btn-success btn-sm mt-2">Download <ObjectFinder collection="users" _id={user} />'s Activity</button>
                    </CSVLink>
                ) : ''}
                leftColSize={4}
                actionComponent={(
                    <Row>
                    
                        <Col md={6}>
                            <p className="text-sm mb-0">Select User</p>
                            <SearchCollections
                                collection={'users'}
                                hideTitle={true}
                                placeholder="Search..."
                                value={user}
                                onChange={(obj) => setUser(obj.value)}
                                filter={{
                                    division:  selected_division._id,
                                }}
                            /> 
                        </Col>

                        <Col md={6}>
                            <p className="text-sm mb-0">Select Day</p>
                            <DatePicker 
                                selected={day} 
                                onChange={(date) => setDay(date)} 
                            />
                        </Col>
                    
                    </Row>
                )}
            />

            {loading ? (
                <Circle />
            ) : !user ?(
                <div className="text-center"><i className="fas fa-info-circle text-info mr-2" /> Select A User Above</div>
            ) : !data && user ? (
                <div className="alert alert-danger">An error occurred, please refresh your page.</div>
            ) : (
                <Container className="ful mt--4 pt-4" fluid>

                    <Card>
                            {data.map((d, i) => {

                                let lastTime = data[i -1] ? data[i -1].timeline_timestamp : 0
                                let gap = 0;

                                if(lastTime) gap = d.timeline_timestamp - lastTime

                                const height = d.timeline_type === 'event' ? 0 : Math.floor(gap / 60)

                                let body = d.timeline_type
                                let title = d.timeline_type
                                let icon = ''

                                if(d.timeline_type === 'note') {
                                    body = formatText(d.value).stripHTML(50);
                                    title = 'Note Created'
                                    icon = <i className="fa-solid fa-note-sticky" />
                                } else if(d.timeline_type === 'document') {
                                    body = d.name
                                    title = 'Document Uploaded'
                                    icon = <i className="fa-solid fa-upload" />
                                } else if(d.timeline_type === 'new_item') {
                                    body = d.name
                                    title = 'Created An Outstanding Item'
                                    icon = <i className="fa-solid fa-list-check" />
                                } else if(d.timeline_type === 'finished_item') {
                                    body = d.name
                                    title = 'Complete An Outstanding Document'
                                    icon = <i className="fa-solid fa-list-check" />
                                } else if(d.timeline_type === 'custom_date') {
                                    body = d.name
                                    title = 'Entered A Custom Date'
                                    icon = <i className="fa-solid fa-map-pin" />
                                } else if(d.timeline_type === 'call_offer') {
                                    body = d.status
                                    title = 'Call Offer Given'
                                    icon = <i className="fa-solid fa-headset" />
                                } else if(d.timeline_type === 'call') {
                                    body = d.status
                                    title = 'Phone Call'
                                    icon = <i className="fa-solid fa-phone-volume" />
                                } else if(d.timeline_type === 'page_history') {
                                    if(d.contact) {
                                        body = <ObjectFinder collection="contacts" _id={d.contact} />
                                    } else {
                                        body = <ObjectFinder collection="matters" _id={d.matter} />
                                    }
                                    title = `Opened A ${d.contact ? 'Contact' : 'Matter'} File`
                                    icon = <i className="fa-solid fa-clock-rotate-left" />
                                } else if(d.timeline_type === 'event') {
                                    body = d.name + ' - ' + moment.unix(d.unix_start).format('h:mm A') + ' - ' + moment.unix(d.unix_end).format('h:mm A') 
                                    title = 'Scheduled Event'
                                    icon = <i className="fa-solid fa-calendar" />
                                } else if(d.timeline_type === 'signing_document') {
                                    body = d.name
                                    title = 'Sent Doc For Signature'
                                    icon = <i className="fa-solid fa-pen" />
                                } else if(d.timeline_type === 'task') {
                                    body = d.name
                                    title = 'Finished Task'
                                    icon = <i className="fa-solid fa-check" />
                                } else if(d.timeline_type === 'new_alert') {
                                    body = d.body
                                    title = 'Created Alert'
                                    icon = <i className="fa-solid fa-bell" />
                                } else if(d.timeline_type === 'resolved_alert') {
                                    body = d.body
                                    title = 'Resolved Alert'
                                    icon = <i className="fa-solid fa-bell" />
                                } else if(d.timeline_type === 'step_moved') {
                                    body = <ObjectFinder collection="workflow_steps" _id={d.workflow_step} />
                                    title = 'Moved Step'
                                    icon = <i className="fa-solid fa-shoe-prints" />

                                }

                                return (
                                    <div key={d._id + i}>
                                        <div className="bg-secondary" style={{height: height * 20, position: 'relative'}}>
                                            <div 
                                                style={{
                                                    background: height >= 10 ? COLORS.red : height >= 5 ? COLORS.orange : height >= 3 ? COLORS.cyan : COLORS.green,
                                                    width: 8,
                                                    borderTopLeftRadius: 6  ,
                                                    borderTopRightRadius: 6 ,
                                                    borderBottomLeftRadius: 6   ,
                                                    borderBottomRightRadius: 6  ,
                                                    position: 'absolute',
                                                    left: 0,
                                                    bottom: 0,
                                                    top: 0
                                                }}
                                            />
                                        </div>

                                    

                                        <CardBody className={`${i === 0 ? '' : 'border-top'}`}>
                                            <Row>
                                                <div className="col-auto align-self-center text-center" style={{width: 55}}>
                                                    {icon}
                                                </div>
                                                <Col className="align-self-center">
                                                    <div><p className="text-sm mb--2 text-dark"><small>{title}</small></p></div>
                                                    <div><p className="text-sm mb-0">{body}</p></div>
                                                </Col>
                                                <Col className="align-self-center">
                                                    <p className="text-sm mb-0 text-center">
                                                        {d.matter ? (
                                                            <A href={`${DOMAINS.APP}/matters/${d.matter}`}><ObjectFinder collection="matters" _id={d.matter} /></A>
                                                        ) : d.contact ? (
                                                            <A href={`${DOMAINS.APP}/contacts/${d.matter}`}><ObjectFinder collection="contacts" _id={d.contact} /></A>
                                                        ) : ''}
                                                    </p>
                                                </Col>

                                                <Col className="align-self-center text-right">
                                                    <p className="text-sm mb-0">
                                                        {moment.unix(d.timeline_timestamp).format('h:mm A')}
                                                    </p>
                                                </Col>
                                            </Row>
                                            {/* {d.timeline_type} - {moment.unix(d.timeline_timestamp).format('h:mm A')}  
                                            {gap ? <span> {formatText(gap).secondsToTime()}</span> : ''} */}
                                        </CardBody>

                                    </div>
                                )
                            })}


                    </Card>

                    <div className="pb-6" />

                </Container>
            )}

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
        COLORS: state.config.COLORS,
        DOMAINS: state.config.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(SupervisorTodaysActivity);
