import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import DatePicker from 'react-datepicker';


const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, DOMAINS}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));

    const [ chapter, setChapter ] = useState('all');
    const [ courtCode, setCourtCode ] = useState('all');

    const [ hasFilingFeeDeadline, setFilingFeeDeadline ] = useState(true);
    const [ day, setDay ] = useState(moment().startOf('week').toDate())
    const [ end, setEnd ] = useState(moment().endOf('week').toDate())


    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetCourtCode = useCallback((val) => {
        setCourtCode(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetDay = useCallback((val) => {
        setDay(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    
    const onSetEnd = useCallback((val) => {
        setEnd(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetChapter = useCallback((val) => {
        setChapter(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetFilingFeeDeadline = useCallback((val) => {
        setFilingFeeDeadline(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(courtCode !== 'all') params.filter.court_code = courtCode
        if(chapter !== 'all') params.filter.chapter = chapter

        if(hasFilingFeeDeadline) {
            params.filter.filing_fee_deadline = { $gt: day, $lt: end }
        } else {
            params.filter.filing_fee_deadline = { $exists: false }
        }

        params.sort = { filing_fee_deadline: -1 }


        const query = await api.modules.bk.supervisor.caseSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Case',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                const overdue = row.due_date && row.due_date < now ? true : false;

                return (
                    <div> 
                        <A 
                            href={`${DOMAINS.APP}/matters/${row.matter}?showModule=bk&moduleTab=CaseStage`} 
                            className={`font-weight-bold ${overdue ? 'text-danger text-uppercase' : 'text-dark'}`}
                        >
                            {row.case_title ? row.case_title : <ObjectFinder collection="matters" _id={row.matter} /> }
                        </A>
                    </div>
                )
            },
        },
        {
            dataField: "date",
            text: 'Filing Fee Deadline',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div>{row.filing_fee_deadline ? moment(row.filing_fee_deadline).format('MM/DD/YYYY h:mm A') : ''}</div>
                </div>
            )
        },
        {
            dataField: "case_number",
            text: 'Case Info',
            headerStyle: { width: 150 },
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';

                return (
                    <div>
                        <div>{row.case_number}</div>
                        <div>{row.court_code}</div>
                    </div>
                )
            }
        },
        {
            dataField: "state",
            text: 'State',
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';

                return row.state
            }
        },

        // {
        //     dataField: "due_date",
        //     text: 'due',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div>{!row.due_date ? '-' : row.due_date < Math.floor(new Date() / 1000) ? <span className="text-danger">{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span> : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</div>
        //             </div>
        //         )
        //     )
        // },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/matters/${row.matter}?showModule=bk&moduleTab=CaseStage`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'Bankruptcy Filing Fee Deadlines'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={12}

        />

        <Card className="mt--4">
            <CardBody>

                <Row>
                
                    <Col md={6}>
                        <ReactSelect
                            title={"Select District"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetCourtCode(obj.value)}
                            options={[
                                { value: 'all', label: 'All Districts' },
                                { value: 'miebke', label: 'Eastern District Of Michigan' },
                                { value: 'miwbke', label: 'Western District Of Michigan' },
                                { value: 'ohnbke', label: 'Northern District Of Ohio' },
                                { value: 'ohsbke', label: 'Southern District Of Ohio' },
                            ]}
                            value={courtCode}
                        />    
                    </Col>
                    <Col md={6}>
                        <ReactSelect
                            title={"Chapter"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetChapter(obj.value)}
                            options={[
                                { value: 'all', label: 'All', },
                                { value: 7, label: '7'  },
                                { value: 13, label: '13'  },
                            ]}
                            value={chapter}
                        />    
                    </Col>
                    <Col md={4}>
                        <ReactSelect
                            title={"Has Filing Fee Deadline Date"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetFilingFeeDeadline(obj.value)}
                            options={[
                                { value: true, label: 'Yes', },
                                { value: false, label: 'No'  },
                            ]}
                            value={hasFilingFeeDeadline}
                        />    
                    </Col>

                    <Col md={4}>
                        <p className={`text-sm mb-0 ${!hasFilingFeeDeadline ? 'text-muted' : ''}`}>
                            Deadlines From {!hasFilingFeeDeadline ? <i className="fas fa-times-circle ml-1 text-warning" /> : ''}
                        </p>
                        <DatePicker 
                            readOnly={!hasFilingFeeDeadline}
                            selected={day} 
                            onChange={(date) => onSetDay(date)} 
                        />
                    </Col>
                

                    <Col md={4}>
                        <p className={`text-sm mb-0 ${!hasFilingFeeDeadline ? 'text-muted' : ''}`}>
                            Deadlines Until {!hasFilingFeeDeadline ? <i className="fas fa-times-circle ml-1 text-warning" /> : ''}
                        </p>
                        <DatePicker 
                            readOnly={!hasFilingFeeDeadline}
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>


            </CardBody>
        </Card>

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search By Item Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
