import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, Col, Container, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import DatePicker from 'react-datepicker';

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, DOMAINS}) => {
    const [ chapter, setChapter ] = useState('all');
    const [ day, setDay ] = useState(moment().startOf('day').toDate())
    const [ end, setEnd ] = useState(moment().endOf('day').toDate())

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetChapter = useCallback((val) => {
        setChapter(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    
    const onSetEnd = useCallback((val) => {
        setEnd(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetDay = useCallback((val) => {
        setDay(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])


   
    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(chapter !== 'all') params.filter.chapter = chapter

        if(day && end) params.filter.created_at = { $gt: parseInt(moment(day).format('X')), $lt: parseInt(moment(end).format('X')) }


        const query = await api.modules.bk.supervisor.newCaseEntriesSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Case Info',
            headerStyle: { width: 120 },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (
                    <div>
                        <div>{row.case_number}</div>
                        <div>{row.court_code}</div>
                    </div>
                )
            },
        },
        {
            dataField: "matter",
            text: 'Matter',
            headerStyle: { width: 200 },

            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                row.matter ? <A href={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></A> :
                ''
            )
        },
        {
            dataField: "created_at",
            text: 'Created At',
            headerStyle: { width: 200 },

            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')
            )
        },
        {
            dataField: "docket_text",
            text: 'Docket Text',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.docket_text
            )
        },
        {
            dataField: "chapter",
            text: 'Chapter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.chapter
            )
        },
        {
            dataField: "deleted",
            text: 'Type',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                row.matter ? <Badge style={{width: 100}} color="success">Matched</Badge> :
                row.deleted ? <Badge style={{width: 100}} color="warning">Deleted</Badge> :
                <Badge style={{width: 100}} color="danger">Unmatched</Badge>
            )
        },

    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'New Cases Filed'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description="Search By Time Span"
            leftColSize={5}
            actionComponent={(
                <Row>
                
                    <Col md={3}>

                    </Col>

                    <Col md={3}>
                        <ReactSelect
                            title={"Select Chapter"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetChapter(obj.value)}
                            options={[
                                { value: 'all', label: 'All Chapters' },
                                { value: 7, label: 'Chapter 7' },
                                { value: 13, label: 'Chapter 13' },
                            ]}
                            value={chapter}
                        />    
                    </Col>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Filed Start</p>
                        <DatePicker 
                            selected={day} 
                            onChange={(date) => onSetDay(date)} 
                        />
                    </Col>
                

                    <Col md={3}>
                        <p className="text-sm mb-0">Filed End</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>

            )}
        />



        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search By Item Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
